@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  color: theme("colors.gray.800");
  background-color: theme("colors.gray.50");
}
